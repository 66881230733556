/* eslint-disable react/jsx-no-undef */
import { get } from 'helpers/api_helper';
import { NumberToWordsConverter, customFormatDate, teethsTable } from 'helpers/basic_helper';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { customRound, formatReferenceNumber } from '../utils/number_formatters'
import moment from 'moment';

const loadingStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

const styleCan = {
    color: 'red',
    position: 'absolute',
    top: '50%',
    fontSize: '92px',
    left: 'calc(100% - 62%)',
    transform: 'rotate(322deg)',
    opacity: 0.1
}

const InvoicePdfview = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [printData, setPrintData] = useState(null);
    const [printContents, setPrintContents] = useState(false);
    const [invoiceType, setInvoiceType] = useState('TAX INVOICE');

    useEffect(async() => {
        setInvoiceType(`${queryParams.get('type')? `${queryParams.get('type').toUpperCase()} `: 'TAX'} INVOICE`);
        const res = await get(`/api/invoice/invoicePrintData/${queryParams.get('type') ? 1 : 2}/${queryParams.get('invoice_number')}`)
        setPrintData(res?.response);
    }, [])

    
    const printPage = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
    }
    window.addEventListener('afterprint', () => { window.close() });

    useEffect(() => {
        if(printContents){
            document.body.innerHTML = printContents;
            setTimeout(function () {
                window.print();
            }, 600);
        }
    }, [printContents]);

    let total_units = 0;
    let total_amount = 0;
    let req_by = {};
    let serialNumber = 1;

    printData?.orders?.forEach((pv) => {
        if (!req_by[pv.doctor]) {
            req_by[pv.doctor] = [];
        }
        req_by[pv.doctor].push(pv);
    });

   // Generate the rows with group names (doctor names) before each group's rows
    const products = Object.keys(req_by).map((doctorName, i) => {
        const groupRows = req_by[doctorName].map((pv, j) => {
            const total = parseFloat(pv.product_unit) * parseFloat(pv.product_price);
            total_units += parseFloat(pv.product_unit);
            total_amount += total;

            const rows = (
                <tr key={`op_${i}_${j}`}>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{serialNumber}</td>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.order_number}</td>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.orderform_number}</td>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{customFormatDate(pv.orderform_date, 'DD/MM/YYYY')}</td>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.patient_name}</td>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{pv.product}</td>
                    <td className="p-0" style={{padding: '0px', width: 'auto', verticalAlign: 'middle'}}>{teethsTable(JSON.parse(pv.teeth))}</td>
                    <td className="p-0 ps-1 text-center">{pv.product_unit}</td>
                    <td className="p-0 ps-1" style={{fontSize: '10px'}}>{parseFloat(pv.product_price).toFixed(2)}</td>
                    <td className="p-0 ps-1 pe-1" style={{textAlign: 'right', fontSize: '10px'}}>{total.toFixed(2)}</td>
                </tr>
            );

            serialNumber++;
            return rows;
        });

        // Return the group name row followed by the group rows
        return (
            <React.Fragment key={`group_${i}`}>
                <tr>
                    <td colSpan="11" style={{fontSize: '10px', fontWeight: 'bold'}}> {doctorName ? `Requested By Dr. ${doctorName}`: '-' }</td>
                </tr>
                {groupRows}
            </React.Fragment>
        );
    });

    const getGstValue = () => {
        const { invoice } = printData;
        const gstValue = parseFloat(invoice.gst);
        const isPanIndia = printData.invoice.is_panindia > 0;
    
        if (isPanIndia) {
            return (
                <div className="row" style={{ borderBottom: '1px solid #000', margin: '1px -5px'}}>
                    <div className="col-sm-7" style={{ textAlign: 'right' }}>
                        IGST @ {gstValue.toFixed(2)} %
                    </div>
                    <div className="col-sm-5" style={{ textAlign: 'right' }}>
                        {((total_amount * gstValue) / 100).toFixed(2)}
                    </div>
                </div>
            );
        } else {
            const cgstValue = (gstValue / 2).toFixed(2);
            return (
                <>
                    <div className="row" style={{ borderBottom: '1px solid #000', margin: '2px -5px' }}>
                        <div className="col-sm-7" style={{ textAlign: 'right' }}>
                            CGST @ {cgstValue} %
                        </div>
                        <div className="col-sm-5" style={{ textAlign: 'right' }}>
                            {((total_amount * (gstValue / 2)) / 100).toFixed(2)}
                        </div>
                    </div>
                    <div className="row" style={{ borderBottom: '1px solid #000', margin: '4px -5px' }}>
                        <div className="col-sm-7" style={{ textAlign: 'right' }}>
                            SGST @ {cgstValue} %
                        </div>
                        <div className="col-sm-5" style={{ textAlign: 'right' }}>
                            {((total_amount * (gstValue / 2)) / 100).toFixed(2)}
                        </div>
                    </div>
                </>
            );
        }
    };

    const getGrandTotal = () => {
        const { invoice } = printData;
        const gstAmount = (parseFloat(total_amount) * parseFloat(invoice.gst)) / 100
        return customRound(parseFloat(total_amount) + gstAmount);
    }

    return (
        printData !== null ?
        <>
            <div style={{width: '100%', textAlign:'center'}}>
                <button onClick={() => printPage()} className='btn btn-primary' type="button" style={{margin: '26px auto', width: '120px', height: '60px'}}><i className='fas fa-print me-2'></i>Print</button>
            </div>
            <div id="printablediv">
                <div className="print-invoice-container print-container" style={{ margin:' 0 auto'}}>
                    {
                        printData?.invoice?.status == 1 && <h1 style={styleCan}>Cancelled</h1>
                    }
                    <div>
                        <div className="row">
                            <div style={{width: '20%', textAlign:'center'}}>
                                <img src={printData?.org?.logo} style={{width: '148px'}} /> <br /><br />
                                {/* <h2 style={{paddingTop: '10px'}}>{queryParams?.get('type') ? 'PROFORMA ': 'TAX '} INVOICE</h2>
                                <div className='position-absolute' style={{right: '10px', top: '10px', padding: '6px', fontWeight: 'bold'}}>Pirntdate# 26/12/2022</div> */}
                            </div>
                            <div style={{width: '40%', textAlign:'left'}}>
                                <span style={{fontSize: '22px', fontWeight: '700'}}>{(printData?.org?.title)?.toUpperCase()}</span><br />
                                <address style={{width: '300px', marginBottom: '0'}}>{printData?.org?.address}</address>
                                <span>GSTIN: {printData.org.gst}</span><br />
                                <span>HSN Number: {printData.org.hsn}</span><br />
                            </div>
                            <div style={{width: '40%', textAlign:'left', marginTop: '30px'}}>
                                <span>Mobile: {printData?.org?.mobile} (ONLY BILLING) </span><br />
                                <span>E-Mail: {printData?.org?.ac_email}</span><br />
                            </div>
                        </div>

                        <div className="row">
                            <div className='mt-2 mb-0' style={{width: '100%', textAlign:'center', marginTop: '6px'}}>
                                <h4>{invoiceType}</h4>
                            </div>
                        </div>

                        <div style={{width: '100%', display: 'flex'}}>
                            <div style={{padding: '10px', width: '60%'}}>
                                <span style={{fontSize: '18px', fontWeight: '700'}}>Dr. {(printData?.client?.title)?.toUpperCase()}</span><br />
                                <span>{printData?.client?.address}, {printData?.client?.city}, {printData?.client?.state} - {printData?.client?.pincode}</span><br />
                                <span>{printData?.client?.state} State Code:{printData?.client?.state_code}</span><br />
                            </div>

                            <div style={{width: '40%', textAlign: 'right'}}>
                                <strong>Invoice #.</strong> <span>{
                                    queryParams.get('type') ? 
                                    printData?.invoice?.invoice_number:
                                    formatReferenceNumber(printData?.tax_invoice?.invoice_number)

                                }</span><br />
                                <strong>Date</strong> <span>{queryParams.get('type') ? printData.invoice.invoice_date : moment(printData?.tax_invoice?.invoice_date).format('DD-MM-yyyy')}</span>
                                <br />
                                {
                                    printData?.invoice?.status == 1 && 
                                    <>
                                        <br />
                                        <b className='text-danger'>CANCELLED</b>
                                    </>
                                }
                            </div>
                        </div>

                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className="p-0 ps-1" >#</th>
                                    <th className="p-0 ps-1" >Order #</th>
                                    <th className="p-0 ps-1" >Challan #</th>
                                    <th className="p-0 ps-1" >Challan Date</th>
                                    <th className="p-0 ps-1" >Patient</th>
                                    <th className="p-0 ps-1" >Product</th>
                                    <th className="p-0 ps-1" >Teeth</th>
                                    <th className="p-0 ps-1" >Units</th>
                                    <th className="p-0 ps-1" >Rate/Unit </th>
                                    <th className="p-0 ps-1" >Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products}
                                <tr style={{fontWeight: '700'}}>
                                    <td colSpan={7} style={{textAlign: 'right'}}>Sub Total: </td>
                                    <td>{total_units}</td>
                                    <td colSpan={2} style={{textAlign: 'right'}}>{total_amount.toFixed(2)}</td>
                                </tr>
                                <tr rowSpan={2}>
                                    <td colSpan={5}>
                                        <b>GSTIN : {printData.org.gst}</b><br />
                                        <b>HSN Number: {printData.org.hsn}</b><br />
                                        <b>State : {printData.org.state} State Code: {printData.org.state_code}</b>
                                    </td>
                                    <td colSpan={5}>
                                        {getGstValue()}
                                        <div className="row" style={{marginTop: '6px', marginBottom: '0px'}}>
                                            <div className="col-sm-7" style={{textAlign: 'right', fontWeight: '700'}}>
                                                Grand Total
                                            </div>
                                            <div className="col-sm-5" style={{textAlign: 'right', fontWeight: '700'}}>
                                            { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR' }).format(getGrandTotal())}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                    {/* <th className='p-1'>{printData?.invoice.igst > 0 ? parseFloat(printData?.invoice.igst) : (parseFloat(printData?.invoice?.cgst) + parseFloat(printData?.invoice?.sgst)).toFixed(2) }</th> */}
                                <tr>
                                    <td colSpan={12}><strong>Grand Total (₹): <NumberToWordsConverter number={getGrandTotal()} />.</strong></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-sm-6">
                                <span>BANKER NAME:</span><br />
                                <span>1. {printData?.org?.bank?.bank}{' '}{printData?.org?.bank?.branch}</span><br />
                                <span>RTGS/NEFT IFS Code: {printData?.org?.bank?.ifscode}</span><br />
                                <span>C/A no.{printData?.org?.bank?.account} </span><br /><br />
                                <span>2. HDFC BANK</span><br />
                                <span>RTGS/NEFT IFS Code: HDFC0001019</span><br />
                                <span>C/A no.99900609196713  </span>
                            </div>
                            <div className="col-sm-6" style={{textAlign: 'right'}}>
                                <span style={{bottom: '16px'}} >Authorized</span><br />
                                <img width="120" src={printData?.org?.qrlink} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>  : 
        <div style={loadingStyle}>
            <img src="https://dglab.keepsmiles.in/api/uploads/241002/241002_qr.png" style={{width: '92px', marginRight: '16px', float: 'left'}} /><br /><br /><br /><br />
            <span>Loading Data...</span>
        </div>
    )
}

export default InvoicePdfview;